@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Bold.woff') format('woff'),
    url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('./fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Regular.woff') format('woff'),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Medium.woff') format('woff'),
    url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Black.eot');
  src: local('Roboto Black'), local('Roboto-Black'),
    url('./fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Black.woff') format('woff'),
    url('./fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
    url('./fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Light.woff') format('woff'),
    url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Thin.eot');
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('./fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Thin.woff') format('woff'),
    url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

a,
a:hover {
  outline: none;
  color: #333333;
}

a:hover,
a:visited,
a:active {
  text-decoration: none !important;
  outline: none;
}

button {
  border: none;

  &:focus {
    outline: none;
  }
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: none;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

svg {
  overflow: visible;
}

html {
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
  text-rendering: optimizelegibility;
  user-select: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 1.4;
  color: #333333;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.main {
  padding-bottom: 100px;
}

.no-main {
  padding-bottom: 0px;
}

::-webkit-scrollbar {
  /* 1 - скроллбар */
  width: 4px;
  height: 4px;
  background-color: #d1d1d1;
}

::-webkit-scrollbar-thumb {
  /* 5 - ползунок */
  border-radius: 2px;
  background-color: #ffe600;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: none;
}

.blue {
  background: #67b7f0;
}

.green {
  background: #41bf75;
}

.violet {
  background: #a173cb;
}

.darkBlue {
  background: #546ec8;
}

.darkGreen {
  background: #00a7b1;
}

@media (max-width: 991px) {
  .main {
    z-index: 2;
    position: relative;
    padding-top: 54px;
  }
  ::-webkit-scrollbar {
    /* 1 - скроллбар */
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    /* 5 - ползунок */
    border-radius: 2px;
    background-color: transparent;
  }
}

@media (max-width: 767px) {
  .main {
    padding-bottom: 50px;
  }
}
