.statistics-dates,
.add-interview {
  .flatpickr-month {
    background-color: transparent;
  }

  .flatpickr-weekdays,
  .flatpickr-weekday {
    background-color: transparent;
  }

  .flatpickr-innerContainer {
    border: none;
  }

  .calendar {
    .modal-dialog {
      max-width: 284px;
      margin-top: 90px;
    }
    .modal-content {
      padding: 31px 34px 44px 34px;
    }
    .modal-body {
      padding: 0px;
    }
    .close {
      position: absolute;
      right: 16px;
      top: 16px;
      content: '';
      width: 26px;
      height: 26px;
    }
    &__title {
      font-weight: bold;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      color: #000000;
      margin-bottom: 23px;
      width: 100%;
    }
    .new-account__row-btn {
      max-width: 212px;
      height: 41px;
    }
  }

  .custom-calendar {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
    height: 36px;
    padding: 8px 12px;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
    text-align: center;
    outline: none;

    &:hover,
    &:focus {
      border-color: #bdbdbd;
    }
  }

  .flatpickr-calendar.inline,
  .flatpickr-calendar.open {
    max-width: 212px;
    border: none;
    box-shadow: none;
    margin-top: 18px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }

  .flatpickr-months {
    &:hover {
      background-color: transparent;
    }
    .flatpickr-next-month,
    .flatpickr-prev-month {
      border: 1px solid #828282;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      svg {
        width: 8px;
        height: 12px;
        fill: #828282;
      }
      &:hover {
        background: #333333;
        svg {
          fill: #fff;
        }
      }
    }
  }

  .flatpickr-current-month {
    font-family: 'Roboto';

    .flatpickr-monthDropdown-months {
      padding-left: 0px;
      max-width: 92px;
      width: 100%;
      background-color: transparent;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #333333;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url('../../../assets/image/arrow.svg');
      background-size: 8px;
      background-position: calc(100% - 5px) center;
      background-repeat: no-repeat;
      &:hover {
        background-color: transparent;
      }
    }
    input.cur-year {
      padding-left: 0px;
      max-width: 78px;
      width: 100%;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #333333;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
    .flatpickr-monthDropdown-month {
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #333333;
      background-color: #fff;
      border: none;
    }
    option {
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #333333;
      background-color: #fff;
      border: none;
    }
    .numInputWrapper {
      max-width: 59px;
    }
  }

  .flatpickr-rContainer {
    width: 100%;
  }

  .flatpickr-days {
    width: 100%;
    border: none;
    .dayContainer {
      width: 100%;
      min-width: 212px;
    }
  }

  .flatpickr-day {
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #4f4f4f;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 50%;
    width: 21px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #fedc2d;
      color: #000000;
      border: none;
    }
    &.inRange {
      border-radius: 0;
      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: none;
  }

  .flatpickr-day.endRange.startRange,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange {
    background: #fedc2d;
    border: none;
    z-index: 2;
    color: #000000;
    font-weight: bold;
  }

  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange {
    border-radius: 50px 0px 0px 50px;
  }

  span.flatpickr-weekday {
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    text-transform: lowercase;
  }

  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange {
    background: #fedc2d;
    z-index: 2;
    border: none;
    color: #000000;
    font-weight: bold;
  }

  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.endRange {
    border-radius: 0px 50px 50px 0px;
  }

  .flatpickr-calendar:after,
  .flatpickr-calendar:before {
    display: none;
  }

  .flatpickr-day.endRange.startRange.endRange,
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange {
    background: #fedc2d;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #fedc2d;
    border-color: #fedc2d;
    color: #000000;
  }
}

.add-interview {
  .flatpickr-calendar {
    margin: 0 !important;
  }

  .tempYellow {
    background-color: #fff3b3;
  }

  .disabled {
    pointer-events: none;
  }

  .today.disabled {
    background-color: transparent;
    border-color: #bbb;
  }
}
