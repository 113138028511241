.owl-carousel.important-slider,
.owl-carousel.actions-slider {
  .owl-nav {
    position: absolute;
    right: 0;
    bottom: calc(100% + 20px);
    margin: 0;

    .owl-prev,
    .owl-next {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 0;
      border: 1px solid #828282;
      border-radius: 50%;
      background: transparent;
      transition: all 0.3s ease;

      svg {
        width: 5px;

        path {
          stroke: #828282;
          transition: all 0.3s ease;
        }
      }

      &:hover {
        border-color: #333333;
        background: #333333;

        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }

    .owl-prev {
      margin-right: 15px;
    }
  }

  .owl-dots {
    margin-top: 15px;
  }

  .owl-dots .owl-dot.active span,
  .owl-dots .owl-dot:hover span {
    background-color: #fedc2d;
  }
}
